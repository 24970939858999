<template>
  <div class="useral-insert-term">
    <div class="view-side mt-3 sm:mt-0">
      <div class="view-box">
        <vs-row>
          <vs-col>
            <!--            <custom-profile-image-input v-model="user.image"/>-->

            <div>
              <custom-validate-input :label="$t('terms.labels.name')"
                                     :classes="{'w-full': true}"
                                     align="right"
                                     rtl
                                     v-model="term.name"/>


              <div class="time-picker">
                <div class="date-picker-item">
                  <custom-validate-input :label="$t('terms.labels.startTime')"
                                         :placeholder="'07:00'"
                                         :classes="{'w-full': true}"
                                         :regex="timeRegex"
                                         v-model="term.startTime"/>
                  <date-picker type="time" v-model="term.startTime.value" :max="term.endTime.value"/>
                </div>

                <div class="date-picker-item">
                  <custom-validate-input :label="$t('terms.labels.endTime')"
                                         :placeholder="'23:00'"
                                         :classes="{'w-full': true}"
                                         :regex="timeRegex"
                                         v-model="term.endTime"/>
                  <date-picker type="time" v-model="term.endTime.value" :min="term.startTime.value"/>
                </div>
              </div>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>

    <vs-button v-show="false"
               id="insertTermBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment-jalaali'
  import DatePicker from 'vue-persian-datetime-picker'
  import CustomValidateInput from '../../../../components/customInput/customValidateInput'
  import {insertTerm} from '../../../../http/requests/terms'
  import CustomDialog from '../../../../components/customDialog/customDialog'
  import {checkUserPermissions} from '../../../../assets/js/functions'
  import CustomSelect from '../../../../components/customSelect/customSelect'
  import {getTimes} from "../../../../http/requests/times";

  export default {
    name: 'InsertTerm',
    components: {
      DatePicker,
      CustomSelect,
      CustomDialog,
      CustomValidateInput
    },
    props: {
      termId: 0
    },
    data() {
      return {
        timeRegex: this.$validator('regex.time'),
        term: {
          name: {
            value: '',
            isValid: true
          },
          startTime: {
            value: '',
            isValid: true
          },
          endTime: {
            value: '',
            isValid: true
          }
        }
      }
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      sendData() {
        if (!this.term.name.isValid ||
          !this.term.startTime.isValid ||
          !this.term.endTime.isValid) {

          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('terms.notifications.wrongValues'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        if (this.term.startTime.value === this.term.endTime.value) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('terms.notifications.startTimeIsEqualEndTime'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        const term = {
          name: this.term.name.value,
          start_time: this.term.startTime.value,
          end_time: this.term.endTime.value
        }

        if (this.term.role && this.term.role.value > 0 && this.termId !== this.$store.state.auth.term.id) term.role_id = this.term.role.value

        insertTerm(term).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('terms.notifications.insert.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })
          this.$emit('insert')

        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            const error_mapper = {
              'name': this.$t('terms.notifications.parseError.name'),
              'start_time': this.$t('terms.notifications.parseError.startTime'),
              'end_time': this.$t('terms.notifications.parseError.endTime')
            }

            switch (error.response.status) {
              case 422:
                const errors = error.response.data.errors
                let notifyDelay = 0
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  if (errors[error_key].hasOwnProperty('error_key')) {
                    switch (errors[error_key].error_key) {
                      case 'start_time_equal_to_end_time':
                        setTimeout(() => {
                          this.$vs.notify({
                            title: this.$t('alert.error.title'),
                            text: this.$t('terms.notifications.startTimeIsEqualEndTime'),
                            icon: 'icon-alert-circle',
                            iconPack: 'feather',
                            time: 2400,
                            color: 'danger'
                          })
                        }, notifyDelay)
                        notifyDelay += 750
                        break

                      case 'start_time_conflict':
                        setTimeout(() => {
                          this.$vs.notify({
                            title: this.$t('alert.error.title'),
                            text: this.$t('terms.notifications.parseError.startTimeConflict', {name: errors[error_key].term_name}),
                            icon: 'icon-alert-circle',
                            iconPack: 'feather',
                            time: 5000,
                            color: 'danger'
                          })
                        }, notifyDelay)
                        notifyDelay += 750
                        break

                      case 'end_time_conflict':
                        setTimeout(() => {
                          this.$vs.notify({
                            title: this.$t('alert.error.title'),
                            text: this.$t('terms.notifications.parseError.endTimeConflict', {name: errors[error_key].term_name}),
                            icon: 'icon-alert-circle',
                            iconPack: 'feather',
                            time: 5000,
                            color: 'danger'
                          })
                        }, notifyDelay)
                        notifyDelay += 750
                        break

                      case 'term_range_not_match_with_times':
                        setTimeout(() => {
                          this.$vs.notify({
                            title: this.$t('alert.error.title'),
                            text: this.$t('terms.notifications.parseError.termRange'),
                            icon: 'icon-alert-circle',
                            iconPack: 'feather',
                            time: 5000,
                            color: 'danger'
                          })
                        }, notifyDelay)
                        notifyDelay += 750
                        break
                    }
                  } else {
                    const err = error_key.toString().split('.')
                    if (error_mapper[err[err.length - 1]]) {
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: error_mapper[err[err.length - 1]],
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather',
                        time: 2400
                      })
                      error_mapper[err[err.length - 1]] = null
                    }
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('terms.notifications.insert.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .useral-insert-term {
    width: 100%;
    /*height: calc(100% - 10px);*/
    display: flex;

    @media (max-width: 767px) {
      display: block;
    }

    .menu-side {
      width: 200px;
      min-width: 200px;
      height: 100%;
      padding: 0 10px 0 0;

      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        padding: 0;
        height: fit-content;
      }

      ul {

        li {
          position: relative;
          border: 1px solid #cecece;
          border-radius: .5rem;
          margin-bottom: 10px;
          padding: 0 10px;
          line-height: 40px;
          cursor: pointer;
          transition: all 0.3s ease;

          .icon-chevron-left {
            position: absolute;
            height: fit-content;
            top: 0;
            bottom: 0;
            right: 6px;
            margin: auto;
          }

          &:hover {
            transform: translateX(5px);
          }
        }
      }
    }

    .view-side {
      flex-grow: 1;
      height: 100%;
      border: 1px solid #cecece;
      padding: 5px;
      border-radius: .5rem;
      overflow-y: auto;


      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        height: calc(100% - 230px);
      }

      .view-box {
        /*min-height: 300px;*/
        width: 100%;
        height: 100%;
        padding: 5px;
        overflow: auto;

        .custom-profile-image-input {
          label {
            height: 110px;
            width: 110px;
          }
        }

        .time-picker {
          display: flex;
          justify-content: space-between;

          .date-picker-item {
            border: 0 !important;
            border-radius: 0.4rem;
            position: relative;
            flex-grow: 0.5;
            max-width: 48%;

            .custom-input {
              margin: 0;
            }

            .date-picker-label {
              font-size: 12px;
              position: absolute;
              top: -10px;
              left: 5px;
              padding: 0 5px;
              background-color: #ffffff;
              z-index: 1;
            }

            .vpd-input-group {
              width: 20px !important;
              position: absolute;
              top: 6px;
              left: 2px;

              .vpd-icon-btn {
                opacity: 1 !important;
                height: 25px !important;
                width: 20px !important;
                background-color: inherit !important;

                svg {
                  fill: #000000;
                  overflow: visible !important;
                }
              }

              .form-control {
                border: 0;
                width: 100%;
                display: none;
                line-height: 35px;
              }
            }
          }
        }

        .custom-input:last-child {
          margin-bottom: 0;
        }

        .input-field-item {
          position: relative;
          border: 1px solid #cdcdcd;
          border-radius: 0.4rem;
          padding: 5px 10px;
          margin: 15px 0;
          display: flex;
          justify-content: space-around;
          min-height: 35px;

          &.invalid {
            border-color: #b00000;

            .input-field-label {
              color: #b00000;
            }
          }

          .input-field-label {
            position: absolute;
            font-size: 12px;
            top: -10px;
            left: 8px;
            background-color: #ffffff;
            padding: 0 5px;
          }

          .radio-item {
            display: flex;
            align-items: center;
            direction: rtl;

            label {
              margin-left: 5px;
            }
          }
        }

        div.edit-user-field {
          position: relative;

          span.label {
            font-size: 12px;
            position: absolute;
            top: -10px;
            left: 7px;
            z-index: 10000;
            background: #ffffff;
            padding: 0 5px;
          }

          .useral-custom-element-select {
            margin: 15px 0;

            .selected {
              height: 35px;
              line-height: 35px;
              font-size: 13px;
            }

            .items {
              line-height: 35px;

              div {
                height: 35px;
                font-size: 13px;
              }
            }
          }
        }

        .error-alert {
          display: inline-block;
          border: 1px solid #ffba00;
          border-radius: .5rem;
          color: #ffba00;
          padding: 0 10px;
          line-height: 50px;
          height: 50px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 15px;

          i {
            font-size: 15px;
          }
        }

        &::-webkit-scrollbar {
          display: block;
          border-radius: .2rem;
          width: 10px;
          background: #f9f9fd;
          right: 10px;
        }

        &::-webkit-scrollbar-thumb {
          display: block;
          border-radius: .2rem;
          background: #cecece;
        }
      }
    }
  }
</style>
